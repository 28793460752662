import Vue from 'vue'
import Vuex from 'vuex'
import microbit from './modules/microbit'
import preferences from './modules/preferences'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    microbit,
    preferences
  }
})
