import Vue from 'vue'
const USB = navigator.usb
const DAPjs = require('dapjs') // importはダメ
import {computeHash} from '../../lib/mbit-hash'
import env from '../../env'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(dayjs.tz.guess())

const state = {
  device: null,
  graphValue: localStorage.getItem('graphValue') ? JSON.parse(localStorage.getItem('graphValue')) : [],
  graphValueSub: localStorage.getItem('graphValueSub') ? JSON.parse(localStorage.getItem('graphValueSub')) : [],
  pauseFlag: false
}

const getters = {
  connected() {
    return !!state.device
  },
  values() {
    return {
      main: state.graphValue,
      sub: state.graphValueSub
    }
  }
}

const mutations = {
  addDevice(state, dev) {
    Vue.set(state, 'device', dev)
  },
  rmDevice(state) {
    Vue.set(state, 'device', null)
  },
  addValue(state, { isMain, newValue }) {
    if (!state.pauseFlag) {
      if (isMain) {
        state.graphValue.push(newValue)
        localStorage.setItem('graphValue', JSON.stringify(state.graphValue))
      } else {
        state.graphValueSub.push(newValue)
        localStorage.setItem('graphValueSub', JSON.stringify(state.graphValueSub))
      }
    }
  },
  resetValue(state) {
    state.graphValue = []
    localStorage.setItem('graphValue', JSON.stringify([]))
    state.graphValueSub = []
    localStorage.setItem('graphValueSub', JSON.stringify([]))
  },
  pause(state) {
    state.pauseFlag = !state.pauseFlag
  }
}

const actions = {
  connect(ctx) {
    let chars = ''
    const handler = async str => {
      console.debug(`receive str: ${str}`)
      if ((RegExp('^\u{FFFD}{2}', 'u').test(str))) // 先頭のごみを削除
        str = str.slice(2)

      const parsed = str.split(',')
      if (parsed[0] == '' && parsed.length === 1) {
        return
      }

      const received = {
        t: parsed[0],
        s: parsed[1],
        m: parsed[2],
        a: parsed[3],
        v: parsed[4]
      }
      const hash = computeHash(`${parsed[0]},${parsed[1]},${parsed[2]},${parsed[3]},${parsed[4]}`)
      if (hash != parsed[5]) {
        console.warn(`checksum error. ${hash}`)
        return
      }
      if (received.m === 'd') {
        let isMain = true
        if (received.a === '1') {
          isMain = false
        }

        return ctx.commit('addValue', {
          isMain: isMain,
          newValue: {
            y: received.v,
            x: dayjs().tz().format()
          }
        })
      } else { // received.m が未定義の場合
        console.error('CSV format error. "m" is undefined.')
      }
    }
    if (USB) {
      let target
      if (!USB.ondisconnect) {
        // eslint-disable-next-line no-unused-vars
        USB.ondisconnect = _ => {
          ctx.commit('rmDevice')
        }
      }
      return USB.requestDevice({
        'filters': [
          { vendorId: env.USB_CONFIG.vendorId, product_id: env.USB_CONFIG.vendorId }
        ]
      })
        .then(dev => {
          const transport = new DAPjs.WebUSB(dev)
          target = new DAPjs.DAPLink(transport)
          target.on(DAPjs.DAPLink.EVENT_PROGRESS, data => {
            console.warn(data)
          })
          target.on(DAPjs.DAPLink.EVENT_SERIAL_DATA, data => {
            console.debug(data)
            // if (/^[\u0020]*\u000D\u000A$/.test(data)) { // ゴミ行(CRLF)を削除
            //     return
            // }
            chars += data.toString('utf8')
            if (chars.includes('\r\n')) {
              const separated = chars.split('\r\n')
              handler(separated[0].trim())
              chars = separated[1].toString('utf8')
            }
          })
          return
        })
        .then(() => target.connect())
        .then(() => target.setSerialBaudrate(env.USB_CONFIG.baudrate))
        .then(() => {
          target.startSerialRead(0)
          ctx.commit('addDevice', target)
        })
    } else {
      return Promise.reject('WebUSB is not supported.')
    }
  },
  disconnect(ctx) {
    if (!ctx.state.device)
      return Promise.reject('device is not connected.')
    return Promise.resolve()
      .then(() => ctx.state.device.stopSerialRead())
      .then(() => ctx.state.device.disconnect())
      .then(() => ctx.commit('rmDevice'))
  },
  serialWrite(ctx, str) {
    console.debug(`send "${str}" to microbit`)
    if (ctx.state.device)
      return new Promise((resolve) => {
        ctx.state.device.stopSerialRead()
        setTimeout(() => {
          resolve()
        }, 1000)
      })
        .then(() => ctx.state.device.serialWrite('' + str))
        .then(() => ctx.state.device.startSerialRead(0))
    return Promise.reject('fuga')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
