<template>
  <section>
    <img
      src="../../../public/img/blank.png"
      alt="micro:bit"
      :class="{active: connected, 'mb-icon': true}"
    >
    <div class="group-select-box">
      <button @click="connectUsb">
        {{ $t(connectButtonString) }}
      </button>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions} from 'vuex'
const USB = navigator.usb

export default {
  data() {
    return {
      isUsbDisabled: !USB
    }
  },
  computed: {
    ...mapGetters({
      connected: 'microbit/connected',
    }),
    connectButtonString: function() {
      return this.connected ? 'micro:bit.disconnect' : 'micro:bit.connect'
    }
  },
  watch: {
    selected: function(){
      if (this.connected) {
        this.mDisConnect()
          .catch((e) => {
            console.error(e)
            this.$buefy.toast.open({
              duration: 7000,
              message: this.$t('app.dashboard.graph.error_warning'),
              position: 'is-top',
              type: 'is-danger'
            })
            return Promise.resolve()
          })
      }
    }
  },
  methods: {
    connectUsb() {
      if (this.connected) {
        this.mDisConnect()
      } else {
        this.mConnect()
          .catch(e => {
            console.error(e)
            if (e.message === 'No device selected') {
              this.$buefy.toast.open({
                duration: 7000,
                message: this.$t('error.microbit_connect'),
                position: 'is-top',
                type: 'is-danger'
              })
            }
            return Promise.resolve()
          })
      }
    },
    ...mapActions({
      mConnect: 'microbit/connect',
      mDisConnect: 'microbit/disconnect'
    })
  }
}

</script>
<style scoped>
.mb-icon {
  display: block;
  width: 120px;
  height: 100px;
  margin: 0 auto 15px auto;
  background: url("../../../public/img/status-microbit.png");
}
.mb-icon.active {
  background: url("../../../public/img/status-microbit.png") 120px 0;
}
.group-select-box {
  height: 30px;
  margin-bottom: 40px;
  text-align:center;
}
.group-select-box select {
  width: calc(100% - 35px);
  padding: 0 10px;
  height: 100%;
  border-radius: 4px;
  font-size: 0.9375rem;
  cursor: pointer;
  background: #fff;
}
.group-select-box .fa-caret-down {
  position: absolute;
  top: 50%;
  right: 45px;
  transform: translateY(-50%);
  pointer-events: none;
}
.group-select-box button {
  padding:0 10px 0 40px;
  height: 100%;
  cursor: pointer;
  border-radius: 4px;
  background: url("../../../public/img/icon-webusb.png") #F3F3F3 no-repeat center left 5px;
  text-align: center;
}

.group-select-box button:hover{
  opacity:.8;
}

.group-select-box button .fa-plug {
  font-size: 1rem;
}
.group-select-box button.active {
  background: #ffeb3b;
}
</style>
